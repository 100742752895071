<template>
  <div id="prepareTimeSelect">
    <v-row justify="center">
      <v-col cols="12" md="9" class="px-0 px-sm-3">
        <template v-if="asap === null || skip_validate">
          <v-row>
            <v-btn
              color="primary"
              @click="$emit('back')"
              style="height: 30px; width: 100px"
              outlined
              class="ml-6 ml-sm-0"
            >
              <v-icon>mdi-arrow-left</v-icon>
              {{ $t("back") }}
            </v-btn>
          </v-row>
          <v-row class="options">
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-col cols="6" md="3">
              <v-card
                @click="
                  asap = true;
                  skip_validate ? validate() : null;
                "
              >
                <v-card-title>{{ $t("prepareTimeSelect.asap") }}</v-card-title>
                <v-btn
                  color="primary"
                  fab
                  outlined
                  x-large
                  class="my-5 text-h2"
                >
                  ?
                </v-btn>
              </v-card>
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-col cols="6" md="3">
              <v-card @click="asap = false">
                <v-card-title>{{
                  $t("prepareTimeSelect.select")
                }}</v-card-title>

                <v-btn color="primary" fab icon outlined x-large class="my-5">
                  <v-icon>$calendar</v-icon>
                </v-btn>
              </v-card>
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          </v-row>
        </template>
        <v-row>
          <v-col cols="4" v-if="asap === false && skip_validate">
            <label>Tipo de reserva</label>
          </v-col>
          <v-col cols="4" v-if="asap === false && skip_validate">
            <v-select
              v-model="customer.reservation_type"
              outlined
              dense
              hide-details
              :items="reservation_system_items"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="asap && !skip_validate">
        <HelpDateTimeSelect
          @validated="validate"
          @back="asap = null"
          :skip_validate="skip_validate"
        />
      </v-col>
      <v-col cols="12" v-else-if="asap === false && customer.reservation_type">
        <date-time-select
          :skip_validate="skip_validate"
          ref="dateTimeSelect"
          @validated="validate"
          @back="asap = null"
          :select="
            $route.name == 'customer_inscription_studio' ? 'tattooer' : 'studio'
          "
          v-if="customer.reservation_type === 'day'"
        ></date-time-select>
        <LaborExange
          v-else
          :skip_validate="skip_validate"
          @validated="validate"
          @back="asap = null"
          ref="labor"
          :type="customer.reservation_type"
        ></LaborExange>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["skip_validate"],
  components: {
    DateTimeSelect: () =>
      import("@/components/inscription/customer/DateTimeSelect"),
    LaborExange: () => import("@/components/inscription/customer/LaborExange"),
    HelpDateTimeSelect: () =>
      import("@/components/inscription/customer/HelpDateTimeSelect"),
  },
  computed: {
    ...mapState("inscriptions", ["studioState", "customerState"]),
    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
  },
  methods: {
    validate() {
      this.customer.asap = this.asap;
      this.$emit("validated");
    },
  },
  data() {
    return {
      reservation_system_items: [
        { text: this.$t("reservation_systems.day"), value: "day" },
        { text: this.$t("reservation_systems.week"), value: "week" },
        { text: this.$t("reservation_systems.month"), value: "month" },
      ],
      yesNo: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
      asap: null,
    };
  },
};
</script>

<style scoped lang="sass">
#prepareTimeSelect
  .options
    margin-right: 0
    margin-left: 0
    .col-6

      @media (max-width: 600px)
        &:nth-child(2n+1)
          padding-right: 4px
        &:nth-child(2n)
          padding-left: 4px

  .v-card
    text-align: center
    margin-top: 15px
    height: 86%
    @media (max-width: 600px)
      height: 90%

    .v-card__title
      display: inline-block
      width: 100%
      @media (min-width: 600px)
        white-space: nowrap
      @media (max-width: 600px)
        height: 60px

    &:hover
      border: 1px solid var(--v-primary-base) !important
    .v-btn--fab.v-size--x-large
      height: 100px
      width: 100px
      .v-icon
        height: 50px
        font-size: 50px
        width: 50px
</style>